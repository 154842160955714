import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
import Apps from '../views/apps/apps.vue'
import StoryMaker from '../views/apps/story_maker/story_maker.vue'
import GeneralArchitecture from '../views/apps/general_architecture/general_architecture.vue'
import LegalTextBuilder from '../views/apps/legal_text_builder/legal_text_builder.vue'
import VoyAConstruir from '../views/apps/voy_a_construir/voy_a_construir.vue'
import OtraPuerta from '../views/apps/otra_puerta/otra_puerta.vue'
import Statistics from '../views/apps/statistics/statistics.vue'
import Personal from '../views/apps/personal/personal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/personal/doc',
    name: 'Apps',
    component: Apps
  },
  {
    path: '/personal/doc/story-maker',
    name: 'StoryMaker',
    component: StoryMaker
  },
  {
    path: '/personal/general-architecture',
    name: 'GeneralArchitecture',
    component: GeneralArchitecture
  },
  {
    path: '/personal/doc/legal-text-builder',
    name: 'LegalTextBuilder',
    component: LegalTextBuilder
  },
  {
    path: '/personal/doc/voy-a-construir',
    name: 'VoyAConstruir',
    component: VoyAConstruir
  },
  {
    path: '/personal/doc/otra-puerta',
    name: 'OtraPuerta',
    component: OtraPuerta
  },
  {
    path: '/personal/doc/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/personal/doc/personal',
    name: 'Personal',
    component: Personal
  },
  {
    path: '/*',
    name: 'UrlNotFound',
    component: Home
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes
});

export default router
