// @ is an alias to /src


export default {
  name: 'Apps',
  data: function() {
    return {
      publicPath: process.env.BASE_URL,
      apps: [
        {
          id: 2,
          cols: 3,
          description: 'Book editor and client for stories in the format of choose-your-own-adventure. It allow you to add choices for the reader, ad create multiple storylines',
          title: 'Choose your own adventure',
          img: `choose-your-own-adventure.jpg`,
          routerConfig: {
            name: 'StoryMaker'
          }
        }
        ,
        {
          id: 3,
          cols: 3,
          title: 'Automatic text/report builder',
          img: 'legal-text-builder.jpg',
          description: 'It allows you to define rules and patterns from which a text/report is build. It uses variables, decision trees and component hierarchy',
          routerConfig: {
            name: 'LegalTextBuilder'
          }
        },
        {
          id: 5,
          cols: 3,
          title: 'Otra Puerta',
          img: 'otra-puerta.jpg',
          description: 'Is like Netflix, but books instead of movies. It allows the users to both read and upload their own books. It supports revenue share.',
          routerConfig: {
            name: 'OtraPuerta'
          }
        },
        {
          id: 4,
          cols: 3,
          title: 'Voy A Construir',
          img: 'voy-a-construir.jpg',
          description: 'E-Commerce for construction material. It includes features to sell products and services. It accepts payments from MercadoPago',
          routerConfig: {
            name: 'VoyAConstruir'
          }
        }
        /*
        {
          id: 6,
          title: 'statistics',
          routerConfig: {
            name: 'Statistics'
          }
        },
        {
          id: 7,
          title: 'personal website',
          routerConfig: {
            name: 'Personal'
          }
        }
         */
      ]
    }
  },
  components: {

  },
  methods: {
    gotoApp(routerConfig) {
      this.$router.push(routerConfig);
    },
    buildImagePath(filename) {
      return `${this.publicPath}${filename}`;
    }
  }
}