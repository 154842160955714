
export default {
  name: 'Spa',

  components: {

  },
  methods: {
    gotoHome() {
      this.$router.push({'name': 'Home'})
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
};