
// @ is an alias to /src

export default {
  name: 'LegalTextBuilder',
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  components: {

  }
}