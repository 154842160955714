// @ is an alias to /src

export default {
  name: 'Home',
  data: function() {
    return {
      publicPath: process.env.BASE_URL,
      apps: [
        {
          id: 2,
          cols: 12,
          description: 'Description of the main architucte that host this site and also all the other applications',
          title: 'General Architecture',
          img: `cloud.jpg`,
          routerConfig: {
            name: 'GeneralArchitecture'
          }
        }
        ,
        {
          id: 3,
          cols: 12,
          title: 'Applications',
          img: 'apps-small.png',
          description: 'List of all the sample applications',
          routerConfig: {
            name: 'Apps'
          }
        }
      ]
    }
  },
  components: {

  },
  methods: {
    gotoApp(routerConfig) {
      this.$router.push(routerConfig);
    },
    buildImagePath(filename) {
      return `${this.publicPath}${filename}`;
    }
  }
}