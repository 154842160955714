
// @ is an alias to /src

export default {
  name: 'OtraPuerta',
  components: {

  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}